import { Link } from "gatsby"
import React from "react"

import { graphql, useStaticQuery } from "gatsby"
import Image from "gatsby-image"

import "./footer.scss"

import Phone from "../../images/icons/phone.svg"

const Footer = () => {
  const imageDakshinamurthi = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "dakshinamurthi.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <footer className="footer">
      <Image
        className="footer__image"
        fluid={imageDakshinamurthi.file.childImageSharp.fluid}
        alt="Lord Dakshinamurthi"
      />
      <div className="footer__wrap">
        <div className="footer__head">
          <ul className="footer__link-group">
            <li>
              <Link
                className="footer__link"
                to="/"
                title="Ashtamangalaprasnam Home"
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                className="footer__link"
                to="/#contact-us"
                title="Get in touch with us"
              >
                Contact Us
              </Link>
            </li>
            <li>
              <Link
                className="footer__link"
                to="/administrator"
                title="Aravind Karavattu - The Administrator Page"
              >
                Aravind - The Administrator
              </Link>
            </li>
            <li>
              <a
                className="button_contact"
                href="tel:+919567752287"
                title="Click to call +91 95677 52287"
              >
                <img src={Phone} alt="" className="button_contact__icon" />
                +91 95677 52287
              </a>
            </li>
            <li>
              <a
                className="button_facebook"
                target="_blank"
                rel="noreferrer"
                title="Ashtamangalaprasnam Facebook page"
                href="https://www.facebook.com/ashtamangalaprasnam"
              >
                <svg
                  viewBox="0 0 36 36"
                  className="a8c37x1j ms05siws hwsy1cff b7h9ocf4"
                  fill="url(#jsc_s_2)"
                  height="36"
                  width="36"
                >
                  <defs>
                    <linearGradient
                      x1="50%"
                      x2="50%"
                      y1="97.0782153%"
                      y2="0%"
                      id="jsc_s_2"
                    >
                      <stop offset="0%" stopColor="#0062E0"></stop>
                      <stop offset="100%" stopColor="#19AFFF"></stop>
                    </linearGradient>
                  </defs>
                  <path d="M15 35.8C6.5 34.3 0 26.9 0 18 0 8.1 8.1 0 18 0s18 8.1 18 18c0 8.9-6.5 16.3-15 17.8l-1-.8h-4l-1 .8z"></path>
                  <path
                    className="p361ku9c"
                    fill="#fff"
                    d="M25 23l.8-5H21v-3.5c0-1.4.5-2.5 2.7-2.5H26V7.4c-1.3-.2-2.7-.4-4-.4-4.1 0-7 2.5-7 7v4h-4.5v5H15v12.7c1 .2 2 .3 3 .3s2-.1 3-.3V23h4z"
                  ></path>
                </svg>
                fb.com/ashtamangalaprasnam
              </a>
            </li>
          </ul>
          <ul className="footer__link-group">
            <li>
              <Link
                className="footer__link"
                to="/#our-services"
                title="Ashtamangalaprasnam Serviecs"
              >
                Our Services
              </Link>
            </li>
            <li>
              <Link
                className="footer__link footer__link_secondary"
                to="/daivajnas"
                title="Daivajnas"
              >
                Daivajnas
              </Link>
            </li>
            <li>
              <Link
                className="footer__link footer__link_secondary"
                to="/services/horoscope-matching"
                title="Horoscope Matching"
              >
                Horoscope Matching
              </Link>
            </li>
            <li>
              <Link
                className="footer__link footer__link_secondary"
                to="/services/horoscope-prediction"
                title="Horoscope Prediction"
              >
                Horoscope Prediction
              </Link>
            </li>
            <li>
              <Link
                className="footer__link footer__link_secondary"
                to="/services/horary-prediction"
                title="Horary Prediction"
              >
                Horary Prediction (Kavadi)
              </Link>
            </li>
            <li>
              <Link
                className="footer__link footer__link_secondary"
                to="/services/horoscope-writing"
                title="Horoscope Writing"
              >
                Horoscope Writing
              </Link>
            </li>
            {/* <li>
              <Link
                className="footer__link footer__link_secondary"
                to="/services/electional-astrology"
                title="Electional Astrology"
              >
                Electional Astrology (Muhoortha)
              </Link>
            </li> */}
          </ul>
          <ul className="footer__link-group">
            <li>
              <Link className="footer__link" to="/blog" title="Blog">
                Blog
              </Link>
            </li>
            <li>
              <Link
                className="footer__link footer__link_secondary"
                to="/blog/curse-of-the-nagas"
                title="The Curse of the Nagas"
              >
                The Curse of the Nagas
              </Link>
            </li>
            <li>
              <Link
                className="footer__link footer__link_secondary"
                to="/blog/rudrakshas"
                title="Rudrakashas"
              >
                Rudrakashas
              </Link>
            </li>
            <li>
              <Link
                className="footer__link footer__link_secondary"
                to="/blog/gems"
                title="Gemstones"
              >
                Gemstones
              </Link>
            </li>
            <li>
              <Link
                className="footer__link footer__link_secondary"
                to="/blog/yanthras"
                title="Yanthras"
              >
                Yanthras
              </Link>
            </li>
          </ul>
        </div>
        <p className="p_sm footer__bottom">
          © {new Date().getFullYear()}
          {` `}
          ashtamangalaprasnam.com
          <br />
          Developed by{` `}
          <a
            className="developedByLink"
            title="link to Sandeep's profile"
            href="https://www.linkedin.com/in/sandeepcnath/"
            target="_blank"
            rel="noreferrer"
          >
            Sandeep C Nath
          </a>
        </p>
      </div>
    </footer>
  )
}

export default Footer
